@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@100;200;300;400;500;600;700&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 300;
  font-size: 14px;
}

.listItem:hover {
  background: #e2e8f0;
  cursor: pointer;
}

.github-corner svg {
  position:absolute;
  right:0;
  top:0;
  mix-blend-mode:darken;
  color:#ffffff;
  fill:#000000;
}
.github-corner:hover .octo-arm {
  animation:octocat-wave .56s;
}


@keyframes octocat-wave {
  0%, 100% {
    transform:rotate(0);
  }
  20%, 60% { 
    transform:rotate(-20deg);
  
  } 40%, 80% {
    transform:rotate(10deg);
  }
}